module.exports = {
  siteTitle: 'Anton Konstantinov', // <title>
  manifestName: 'Anton Konstantinov',
  manifestShortName: 'Personal Web Page', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Anton Konstantinov',
  subHeading:
    "Dipl.Ing./M.Eng. Structural Engineering",
  socialLinks: [
    // {
    //   icon: 'fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://de.linkedin.com/in/akanstantsinau',
    },
    {
      icon: 'fa-xing',
      name: 'Xing',
      url: 'https://www.xing.com/profile/Anton_Kanstantsinau',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/antonykonst',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:antonykonst@gmail.com',
    },
  ],
};
